(function (window) {
	var privacyStub = {
		apiQueue: [], // Stores callbacks that are waiting to be processed
		checkInterval: null, // Stores the ID of the timer to enable clearing the interval
		handleApiCall: function (commandName, v, callback) {
			// If PrivacyAPI is not yet ready, enqueue the callback
			if (commandName == "GEO") {
				const geoInfoInternal = this.geoInfo;
				const localStorageValues =
					localStorage && localStorage.getItem("wapo_identity");
				let result = geoInfoInternal(document.cookie);
				if (!result && localStorageValues) {
					let data = JSON.parse(localStorageValues);
					let lsGeoState = data.geoState;
					let lsAddressState = data.addressState;
					const wapoIDState = lsGeoState
						? geoInfoInternal(lsGeoState, true)
						: "";
					const wapoIDAddress = lsAddressState
						? geoInfoInternal(lsAddressState, true)
						: "";
					if (wapoIDAddress || wapoIDState) {
						result = "DNS|US";
					}
				}
				callback && callback(result);
				return result;
			}
			this.apiQueue.push({ commandName, callback });
		},

		/**
		 * Takes cookies and a check mode and returns the geo state
		 *
		 * @param   {string}  geoString    document.cookie value or other string
		 * @param   {true|null}  simpleMode Use simple mode for checking localStorage. Only for checking US States
		 *
		 * @return  {string}        A string that describes
		 * 							the user's privacy eligibility
		 * 							based on geo.
		 */
		geoInfo: function (geoString, simpleMode) {
			let result = "DNS|US";
			if (!simpleMode) {
				let geoCookie = "wp_geo=";
				let optInString = "OPT_IN|";
				if (!new RegExp(geoCookie).test(geoString)) {
					return result;
				}
				if (new RegExp(geoCookie + "[^;]*?\\|EEA").test(geoString)) {
					return optInString + "EEA";
				}
				// CA|QC|||
				if (new RegExp(geoCookie + "CA\\|QC\\|").test(geoString)) {
					return optInString + "QC";
				}
				if (new RegExp(geoCookie + "CA\\|").test(geoString)) {
					// return optInString + "CAN";
				}
			}
			let stateRegex = "(CA|VA|CT|CO|UT|DE|IA|MT|NH|NJ|OR|TX)";
			if (!simpleMode) {
				// equivalent to `new RegExp(/(?:US\|)((CA|VA|CT|CO|UT))/);`
				stateRegex = "(?:US\\|)" + stateRegex;
			}
			// This test doesn't run for localStorage. It's only for cookies
			let eacCheck = /wp_ucp=[^;]*?\|?EAC:(0|1)/.test(geoString);
			// Check if user is in opt out state
			if (new RegExp(stateRegex).test(geoString) || eacCheck) {
				// || Check if the wp_ucp cookie exists and contains `EAC`
				if (eacCheck) {
					result += "|EAC";
				}
				return result;
			}
			return false;
		},

		init: function () {
			// Regularly check if the PrivacyAPI is ready
			// this.checkInterval = setInterval(() => this.checkPrivacyApi(), 500); // Check every 500 milliseconds
			// Expose the handleApiCall method of the stub to the global object
			window.__privacyapi = privacyStub.handleApiCall.bind(privacyStub);
		},
	};
	// Initialize the stub
	window.__privacystub = privacyStub;
	window.__privacystub.init();
})(window);

// Example usage:
// window.__privacystub('QUERY_PERMISSIONS', response => console.log(response));
